import React, { useEffect, useState } from 'react';
import { Moon, Sun } from 'lucide-react';

const AppearanceSettings = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const darkModeEnabled = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(darkModeEnabled);
  }, []);

  const handleThemeChange = (darkMode) => {
    setIsDarkMode(darkMode);
    localStorage.setItem('darkMode', darkMode.toString());
    
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <div className="p-3 rounded-full bg-indigo-500/10">
          <Moon className="w-6 h-6 text-indigo-500" />
        </div>
        <div>
          <h1 className="text-2xl font-semibold text-white">Appearance Settings</h1>
          <p className="text-gray-400">Customize your visual preferences</p>
        </div>
      </div>

      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/10 p-6">
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-lg font-medium text-white">Dark Mode</h3>
            <p className="text-sm text-gray-400">Toggle between light and dark themes</p>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={isDarkMode}
              onChange={(e) => handleThemeChange(e.target.checked)}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300 dark:bg-gray-700 peer-checked:bg-indigo-600 rounded-full peer dark:peer-checked:bg-indigo-300"></div>
            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              {isDarkMode ? 'Enabled' : 'Disabled'}
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default AppearanceSettings; 