import React, { useState } from 'react';
import { Bell, Save } from 'lucide-react';
import './settings.css';
const NotificationSettings = () => {
  const [notifications, setNotifications] = useState({
    pushNotifications: true,
    desktopNotifications: true,
    soundAlerts: true,
    chatNotifications: true,
    taskReminders: true
  });

  const handleChange = (key) => {
    setNotifications(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <div className="p-3 rounded-full bg-blue-500/10">
          <Bell className="w-6 h-6 text-blue-500" />
        </div>
        <div>
          <h1 className="text-2xl font-semibold text-white">Notification Settings</h1>
          <p className="text-gray-400">Manage your notification preferences</p>
        </div>
      </div>

      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/10 p-6 space-y-6">
        {Object.entries(notifications).map(([key, value]) => (
          <div key={key} className="flex items-center justify-between">
            <div>
              <h3 className="text-white font-medium capitalize">
                {key.replace(/([A-Z])/g, ' $1').trim()}
              </h3>
              <p className="text-gray-400 text-sm">
                Enable {key.replace(/([A-Z])/g, ' $1').toLowerCase()}
              </p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={value}
                onChange={() => handleChange(key)}
              />
              <div className="w-11 h-6 bg-gray-700 peer-focus:ring-4 peer-focus:ring-blue-500/20 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationSettings; 