import React from 'react';
import { Shield, Lock, Eye, Save } from 'lucide-react';
import './settings.css';

const PrivacySettings = () => {
  const [privacySettings, setPrivacySettings] = React.useState({
    twoFactorAuth: false,
    activityLog: true,
    dataSharing: false,
    profileVisibility: 'private'
  });

  const handleToggle = (key) => {
    setPrivacySettings(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const handleVisibilityChange = (value) => {
    setPrivacySettings(prev => ({
      ...prev,
      profileVisibility: value
    }));
  };

  return (
    <div className="settings-container">
      <div className="settings-header">
        <div className="settings-header-icon bg-purple-500/10">
          <Shield className="w-6 h-6 text-purple-500" />
        </div>
        <div>
          <h1 className="settings-title">Privacy & Security</h1>
          <p className="settings-subtitle">Manage your security preferences</p>
        </div>
      </div>

      <div className="settings-card">
        <div className="space-y-6">
          {/* Two-Factor Authentication */}
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-white font-medium">Two-Factor Authentication</h3>
              <p className="text-gray-400 text-sm">Add an extra layer of security</p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={privacySettings.twoFactorAuth}
                onChange={() => handleToggle('twoFactorAuth')}
              />
              <div className="w-11 h-6 bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-500/20 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-500"></div>
            </label>
          </div>

          {/* Activity Log */}
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-white font-medium">Activity Log</h3>
              <p className="text-gray-400 text-sm">Track account activity</p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={privacySettings.activityLog}
                onChange={() => handleToggle('activityLog')}
              />
              <div className="w-11 h-6 bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-500/20 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-500"></div>
            </label>
          </div>

          {/* Profile Visibility */}
          <div className="space-y-2">
            <h3 className="text-white font-medium">Profile Visibility</h3>
            <select 
              className="settings-select"
              value={privacySettings.profileVisibility}
              onChange={(e) => handleVisibilityChange(e.target.value)}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
              <option value="team">Team Only</option>
            </select>
          </div>
        </div>

        <div className="settings-divider">
          <button className="settings-button">
            <Save className="w-4 h-4" />
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacySettings; 