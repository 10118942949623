import React from 'react';
import { Globe, Save } from 'lucide-react';
import './settings.css';

const WorkspaceSettings = () => {
  return (
    <div className="settings-container">
      <div className="settings-header">
        <div className="settings-header-icon bg-blue-500/10">
          <Globe className="w-6 h-6 text-blue-500" />
        </div>
        <div>
          <h1 className="settings-title">Workspace Settings</h1>
          <p className="settings-subtitle">Customize your workspace preferences</p>
        </div>
      </div>

      <div className="settings-card">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Workspace Name
            </label>
            <input
              type="text"
              className="settings-input"
              defaultValue="My Workspace"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Timezone
            </label>
            <select className="settings-select">
              <option>Pacific Time (PT)</option>
              <option>Eastern Time (ET)</option>
              <option>Central Time (CT)</option>
              <option>Mountain Time (MT)</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Default Language
            </label>
            <select className="settings-select">
              <option>English (US)</option>
              <option>Spanish</option>
              <option>French</option>
              <option>German</option>
            </select>
          </div>
        </div>

        <div className="settings-divider">
          <button className="settings-button">
            <Save className="w-4 h-4" />
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceSettings; 