import React from 'react';
import { Wallet, CreditCard } from 'lucide-react';
import './settings.css';

const BillingSettings = () => {
  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <div className="p-3 rounded-full bg-yellow-500/10">
          <Wallet className="w-6 h-6 text-yellow-500" />
        </div>
        <div>
          <h1 className="text-2xl font-semibold text-white">Billing & Plans</h1>
          <p className="text-gray-400">Manage your subscription and billing details</p>
        </div>
      </div>

      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/10 p-6 space-y-6">
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-white">Current Plan</h3>
          <div className="p-4 bg-gray-700/50 rounded-lg">
            <div className="flex justify-between items-center">
              <div>
                <h4 className="text-white font-medium">Professional Plan</h4>
                <p className="text-gray-400 text-sm">$29/month</p>
              </div>
              <button className="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-white rounded-lg transition-colors">
                Upgrade Plan
              </button>
            </div>
          </div>
        </div>

        <div className="pt-6 border-t border-gray-700 space-y-4">
          <h3 className="text-lg font-medium text-white">Payment Method</h3>
          <div className="flex items-center gap-3 p-4 bg-gray-700/50 rounded-lg">
            <CreditCard className="w-5 h-5 text-gray-400" />
            <span className="text-white">•••• •••• •••• 4242</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingSettings; 