import React from 'react';
import { SendBirdProvider, Channel, ChannelList } from '@sendbird/uikit-react';
import { X } from 'lucide-react';
import '@sendbird/uikit-react/dist/index.css';

const ChatBox = ({ isOpen, onClose, userId }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed right-4 bottom-4 w-96 h-[600px] bg-white rounded-lg shadow-xl border border-gray-200 overflow-hidden z-50">
      <div className="h-full">
        <SendBirdProvider 
          appId={process.env.REACT_APP_SENDBIRD_APP_ID}
          userId={userId}
        >
          <div className="h-full flex flex-col">
            <div className="flex items-center justify-between p-4 border-b border-gray-200">
              <h3 className="text-lg font-semibold">Messages</h3>
              <button 
                onClick={onClose}
                className="p-2 hover:bg-gray-100 rounded-full"
              >
                <X size={20} />
              </button>
            </div>
            <div className="flex-1 flex">
              <div className="w-1/3 border-r border-gray-200">
                <ChannelList />
              </div>
              <div className="w-2/3">
                <Channel />
              </div>
            </div>
          </div>
        </SendBirdProvider>
      </div>
    </div>
  );
};

export default ChatBox;