import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import TopLoadingBar from '../TopLoadingBar';

const MainLayout = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  // Handle route changes and API requests loading state
  useEffect(() => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Minimum loading time of 1 second

    return () => clearTimeout(timeout);
  }, [location.pathname]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-50 via-blue-50 to-blue-100 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900/90">
      <TopLoadingBar isLoading={isLoading} />
      <Sidebar 
        isCollapsed={isSidebarCollapsed} 
        onToggle={() => setIsSidebarCollapsed(!isSidebarCollapsed)} 
      />
      <Navbar isSidebarCollapsed={isSidebarCollapsed} />
      
      <main 
        className={`
          pt-16 overflow-auto transition-all duration-300
          ${isSidebarCollapsed ? 'ml-20' : 'ml-64'}
        `}
      >
        <div className="h-full">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default MainLayout; 