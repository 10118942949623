import React from 'react';
import { Users, Plus } from 'lucide-react';
import './settings.css';

const TeamSettings = () => {
  const teamMembers = [
    { id: 1, name: 'John Doe', role: 'Admin', email: 'john@example.com' },
    { id: 2, name: 'Jane Smith', role: 'Editor', email: 'jane@example.com' },
  ];

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <div className="p-3 rounded-full bg-green-500/10">
          <Users className="w-6 h-6 text-green-500" />
        </div>
        <div>
          <h1 className="text-2xl font-semibold text-white">Team Management</h1>
          <p className="text-gray-400">Manage your team members and their roles</p>
        </div>
      </div>

      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/10 p-6">
        <div className="flex justify-end mb-4">
          <button className="flex items-center gap-2 px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg transition-colors">
            <Plus className="w-4 h-4" />
            Add Member
          </button>
        </div>

        <div className="space-y-4">
          {teamMembers.map(member => (
            <div key={member.id} className="flex items-center justify-between p-4 bg-gray-700/50 rounded-lg">
              <div>
                <h3 className="text-white font-medium">{member.name}</h3>
                <p className="text-gray-400 text-sm">{member.email}</p>
              </div>
              <div className="flex items-center gap-4">
                <span className="px-3 py-1 bg-green-500/20 text-green-400 rounded-full text-sm">
                  {member.role}
                </span>
                <button className="text-gray-400 hover:text-white">Edit</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamSettings; 