import React, { useState, useEffect, useRef } from 'react';
import { Search, HelpCircle, Bell, LogOut, MessageSquare } from 'react-feather';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchUserAttributes, signOut } from '@aws-amplify/auth';
import { getReports, getOrgConfig, getReportPages } from '../../utils/api';
import ChatBox from '../Chat/ChatBox';

const Navbar = ({ isSidebarCollapsed }) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const profileRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const searchRef = useRef(null);

  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const attributes = await fetchUserAttributes();
        setUserEmail(attributes.email);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };
    getUserInfo();
  }, []);

  useEffect(() => {
    const fetchSearchItems = async () => {
      try {
        const orgConfig = await getOrgConfig();
        const wsId = orgConfig[0].workspaceId;
        
        const reportsResponse = await getReports(wsId);
        
        // Get pages for all reports and flatten into searchable items
        const items = await Promise.all(
          reportsResponse.value.map(async (report) => {
            try {
              const pagesResponse = await getReportPages(report.id, wsId);
              const pages = pagesResponse.value.map(page => ({
                id: `${report.id}-${page.name}`,
                type: 'page',
                name: page.displayName,
                reportName: report.name,
                path: `/report/${report.id}/page/${page.name}`,
                reportId: report.id
              }));

              // Add report as a searchable item
              return [
                {
                  id: report.id,
                  type: 'report',
                  name: report.name,
                  path: `/report/${report.id}/page/${pagesResponse.value[0]?.name}`,
                },
                ...pages
              ];
            } catch (error) {
              console.error(`Error fetching pages for report ${report.id}:`, error);
              return [{
                id: report.id,
                type: 'report',
                name: report.name,
                path: `/report/${report.id}`,
              }];
            }
          })
        );

        setAllItems(items.flat());
      } catch (error) {
        console.error('Error fetching search items:', error);
      }
    };

    fetchSearchItems();
  }, []);

  useEffect(() => {
    // Handle click outside of search results
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Reset search when route changes
  useEffect(() => {
    setSearchTerm('');
    setSearchResults([]);
    setShowResults(false);
  }, [location.pathname]);

  const handleSearch = (value) => {
    setSearchTerm(value);
    if (value.trim() === '') {
      setSearchResults([]);
      return;
    }

    const filtered = allItems.filter(item => 
      item.name.toLowerCase().includes(value.toLowerCase()) ||
      (item.type === 'page' && item.reportName.toLowerCase().includes(value.toLowerCase()))
    );

    setSearchResults(filtered);
    setShowResults(true);
  };

  const handleResultClick = (item) => {
    navigate(item.path);
    setSearchTerm('');
    setShowResults(false);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className={`fixed top-0 h-16 bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm border-b border-gray-200/50 dark:border-gray-700/50 flex items-center justify-between px-6 z-10 transition-all duration-300
      ${isSidebarCollapsed ? 'left-20' : 'left-64'} right-0`}>
      <div className="flex items-center flex-1">
        <div className="max-w-md w-full" ref={searchRef}>
          <div className="relative">
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              onFocus={() => setShowResults(true)}
              placeholder="Search reports and pages..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-500"
            />
            
            {/* Search Results Dropdown */}
            {showResults && searchResults.length > 0 && (
              <div className="absolute top-full left-0 right-0 mt-1 bg-white rounded-lg shadow-lg border border-gray-200 max-h-96 overflow-y-auto">
                {searchResults.map((item) => (
                  <div
                    key={item.id}
                    onClick={() => handleResultClick(item)}
                    className="px-4 py-2 hover:bg-gray-50 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <Search size={16} className="text-gray-400 mr-2" />
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          {item.name}
                        </div>
                        {item.type === 'page' && (
                          <div className="text-xs text-gray-500">
                            in {item.reportName}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      
      <div className="flex items-center space-x-4">
        <button className="p-2 hover:bg-gray-100 rounded-lg">
          <HelpCircle size={20} className="text-gray-600" />
        </button>
        <button 
          className="p-2 hover:bg-gray-100 rounded-lg"
          onClick={() => setIsChatOpen(!isChatOpen)}
        >
          <MessageSquare size={20} className="text-gray-600" />
        </button>
        <button className="p-2 hover:bg-gray-100 rounded-lg relative">
          <Bell size={20} className="text-gray-600" />
          <span className="absolute top-1.5 right-1.5 w-2 h-2 bg-orange-500 rounded-full"></span>
        </button>
        <div className="relative" ref={profileRef}>
          <button 
            className="h-8 w-8 bg-gray-200 rounded-full cursor-pointer hover:ring-2 hover:ring-orange-500 hover:ring-offset-2"
            onClick={() => setShowProfileMenu(!showProfileMenu)}
          />
          
          {showProfileMenu && (
            <div className="absolute right-0 mt-2 w-64 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 py-2">
              {userEmail && (
                <div className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400 border-b border-gray-100 dark:border-gray-700">
                  {userEmail}
                </div>
              )}
              <button
                onClick={handleLogout}
                className="w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100/80 dark:hover:bg-white/5 flex items-center transition-colors duration-150"
              >
                <LogOut size={16} className="mr-2 text-gray-600 dark:text-gray-400" />
                Sign out
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar; 