import React from 'react';
import { Outlet, useLocation, Link } from 'react-router-dom';
import { User, Bell, Shield, Users, Wallet, Globe, Smartphone, Keyboard, Moon } from 'lucide-react';

const SettingsLayout = () => {
  const location = useLocation();
  
  const menuItems = [
    {
      section: "Profile",
      items: [
        { icon: User, label: "Email Preferences", path: "/settings/email" },
        { icon: Bell, label: "Notification Settings", path: "/settings/notifications" },
        { icon: Shield, label: "Privacy & Security", path: "/settings/privacy" }
      ]
    },
    {
      section: "Appearance",
      items: [
        { icon: Moon, label: "Theme Settings", path: "/settings/appearance" }
      ]
    },
    {
      section: "Organization",
      items: [
        { icon: Users, label: "Team Management", path: "/settings/team" },
        { icon: Wallet, label: "Billing & Plans", path: "/settings/billing" },
        { icon: Globe, label: "Workspace Settings", path: "/settings/workspace" }
      ]
    },
    {
      section: "System",
      items: [
        { icon: Smartphone, label: "Device Settings", path: "/settings/devices" },
        { icon: Keyboard, label: "Keyboard Shortcuts", path: "/settings/shortcuts" }
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex gap-8">
          {/* Sidebar Navigation */}
          <div className="w-64 flex-shrink-0">
            <nav className="space-y-8">
              {menuItems.map((section) => (
                <div key={section.section}>
                  <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400 uppercase tracking-wider mb-3">
                    {section.section}
                  </h3>
                  <div className="space-y-1">
                    {section.items.map((item) => (
                      <Link
                        key={item.path}
                        to={item.path}
                        className={`
                          flex items-center px-3 py-2 text-sm rounded-lg transition-colors
                          ${location.pathname === item.path
                            ? 'bg-white dark:bg-gray-800 text-gray-900 dark:text-white shadow-sm'
                            : 'text-gray-700 dark:text-gray-300 hover:bg-white dark:hover:bg-gray-800/50 hover:text-gray-900 dark:hover:text-white'
                          }
                        `}
                      >
                        <item.icon className={`w-5 h-5 mr-3 ${
                          location.pathname === item.path
                            ? 'text-gray-900 dark:text-white'
                            : 'text-gray-500 dark:text-gray-400'
                        }`} />
                        {item.label}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </nav>
          </div>

          {/* Content Area */}
          <div className="flex-1 min-w-0">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsLayout; 