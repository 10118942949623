import React, { useState } from 'react';
import { Mail, Save } from 'lucide-react';
import './settings.css';

const EmailSettings = () => {
  const [emailPreferences, setEmailPreferences] = useState({
    dailyDigest: true,
    weeklyReport: true,
    securityAlerts: true,
    marketingEmails: false
  });

  const handleChange = (key) => {
    setEmailPreferences(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
    <div className="settings-container">
      <div className="settings-header">
        <div className="settings-header-icon bg-orange-500/10">
          <Mail className="w-6 h-6 text-orange-500" />
        </div>
        <div>
          <h1 className="settings-title">Email Preferences</h1>
          <p className="settings-subtitle">Manage how you receive email notifications</p>
        </div>
      </div>

      <div className="settings-card">
        {Object.entries(emailPreferences).map(([key, value]) => (
          <div key={key} className="flex items-center justify-between">
            <div>
              <h3 className="text-gray-900 dark:text-white font-medium capitalize">
                {key.replace(/([A-Z])/g, ' $1').trim()}
              </h3>
              <p className="text-gray-500 dark:text-gray-400 text-sm">
                Receive {key.replace(/([A-Z])/g, ' $1').toLowerCase()} notifications
              </p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={value}
                onChange={() => handleChange(key)}
              />
              <div className="w-11 h-6 bg-gray-200 dark:bg-gray-700 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-orange-500/20 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500"></div>
            </label>
          </div>
        ))}

        <div className="settings-divider">
          <button className="settings-button">
            <Save className="w-4 h-4" />
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailSettings; 