import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';
import { 
  User, Bell, Shield, Globe, 
  Wallet, Building2, Users, Mail,
  LogOut, Smartphone, Keyboard
} from 'lucide-react';

function Settings() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Header */}
      <div className="p-8">
        <div className="flex justify-between items-center mb-2">
          <h1 className="text-2xl font-semibold text-white">Account Settings</h1>
          <button
            onClick={handleLogout}
            className="flex items-center gap-2 px-4 py-2 bg-red-500/10 text-red-400 rounded-lg hover:bg-red-500/20 transition-colors"
          >
            <LogOut size={18} />
            <span>Sign Out</span>
          </button>
        </div>
        <p className="text-gray-400">Manage your account preferences and settings</p>

        {/* Settings Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
          {/* Profile Settings Card */}
          <div className="rounded-xl bg-gradient-to-br from-[#2a2a2a]/90 to-[#1a1a1a]/90 backdrop-blur-xl border border-white/10 p-6">
            <div className="flex items-center gap-4 mb-6">
              <div className="w-12 h-12 rounded-full bg-orange-500/10 flex items-center justify-center">
                <User className="w-6 h-6 text-orange-400" />
              </div>
              <div>
                <h2 className="text-white font-medium">Profile Settings</h2>
                <p className="text-gray-400 text-sm">Update your personal information</p>
              </div>
            </div>
            <div className="space-y-3">
              <button className="w-full text-left p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors">
                <Mail className="inline-block w-5 h-5 mr-3 text-gray-400" />
                Email Preferences
              </button>
              <button className="w-full text-left p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors">
                <Bell className="inline-block w-5 h-5 mr-3 text-gray-400" />
                Notification Settings
              </button>
              <button className="w-full text-left p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors">
                <Shield className="inline-block w-5 h-5 mr-3 text-gray-400" />
                Privacy & Security
              </button>
            </div>
          </div>

          {/* Organization Card */}
          <div className="rounded-xl bg-gradient-to-br from-[#1a2535]/90 to-[#0f172a]/90 backdrop-blur-xl border border-white/10 p-6">
            <div className="flex items-center gap-4 mb-6">
              <div className="w-12 h-12 rounded-full bg-blue-500/10 flex items-center justify-center">
                <Building2 className="w-6 h-6 text-blue-400" />
              </div>
              <div>
                <h2 className="text-white font-medium">Organization</h2>
                <p className="text-gray-400 text-sm">Manage organization settings</p>
              </div>
            </div>
            <div className="space-y-3">
              <button className="w-full text-left p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors">
                <Users className="inline-block w-5 h-5 mr-3 text-gray-400" />
                Team Management
              </button>
              <button className="w-full text-left p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors">
                <Wallet className="inline-block w-5 h-5 mr-3 text-gray-400" />
                Billing & Plans
              </button>
              <button className="w-full text-left p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors">
                <Globe className="inline-block w-5 h-5 mr-3 text-gray-400" />
                Workspace Settings
              </button>
            </div>
          </div>

          {/* System Card */}
          <div className="rounded-xl bg-gradient-to-br from-[#2a1f3f]/90 to-[#1a1a2f]/90 backdrop-blur-xl border border-white/10 p-6">
            <div className="flex items-center gap-4 mb-6">
              <div className="w-12 h-12 rounded-full bg-purple-500/10 flex items-center justify-center">
                <Smartphone className="w-6 h-6 text-purple-400" />
              </div>
              <div>
                <h2 className="text-white font-medium">System</h2>
                <p className="text-gray-400 text-sm">System preferences</p>
              </div>
            </div>
            <div className="space-y-3">
              <button className="w-full text-left p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors">
                <Smartphone className="inline-block w-5 h-5 mr-3 text-gray-400" />
                Device Settings
              </button>
              <button className="w-full text-left p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors">
                <Keyboard className="inline-block w-5 h-5 mr-3 text-gray-400" />
                Keyboard Shortcuts
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings; 