import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import * as pbi from 'powerbi-client';
import { getReports, getOrgConfig, getEmbedToken } from "../utils/api";
import { useContainerSize } from "../hooks/useContainerSize";
import "./Reports.css";
import { useLoading } from "../context/LoadingContext";

function Report() {
  const { reportId, pageName: urlPageName } = useParams();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [powerbi, setPowerbi] = useState(null);
  const [reportInstance, setReportInstance] = useState(null);
  const [workspaceId, setWorkspaceId] = useState(null);
  const { height, width } = useContainerSize();
  // const apiUrl = process.env.REACT_APP_API_BASE_URL || 'https://lodgeic-azf01.azurewebsites.net/api';
  const [isLoaded, setIsLoaded] = useState(false);
  const { startLoading, stopLoading } = useLoading();

  const isMobileDevice = () => {
    return window.innerWidth <= 768; // You can adjust this breakpoint
  };

  useEffect(() => {
    const loadId = 'report-load';
    startLoading(loadId);
    
    const pbiService = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    setPowerbi(pbiService);

    const fetchWorkspaceId = async () => {
      try {
        const orgConfig = await getOrgConfig();
        setWorkspaceId(orgConfig[0].workspaceId);
      } catch (error) {
        console.error('Error fetching workspace ID:', error);
        stopLoading(loadId);
      }
    };

    fetchWorkspaceId();
    
    return () => stopLoading(loadId);
  }, []);

  useEffect(() => {
    async function bootstrapReport() {
      if (!workspaceId || !reportId || !powerbi) return;

      setIsLoaded(false);

      try {
        const reports = await getReports(workspaceId);
        const report = reports.value.find(r => r.id === reportId);
        
        if (!report) {
          throw new Error(`Report with ID ${reportId} not found`);
        }

        const reportContainer = document.getElementById('reportContainer');
        if (reportContainer) {
          powerbi.reset(reportContainer);
          
          const bootstrapConfig = {
            type: 'report',
            id: report.id,
            embedUrl: report.embedUrl,
            accessToken: undefined,
            settings: {
              navContentPaneEnabled: false,
              filterPaneEnabled: true,
              ...(isMobileDevice() && {
                layoutType: pbi.models.LayoutType.MobilePortrait
              })
            }
          };

          console.log('Bootstrapping report...');
          const bootstrappedReport = powerbi.bootstrap(reportContainer, bootstrapConfig);
          setReportInstance(bootstrappedReport);

          const embedTokenResponse = await getEmbedToken(report.id, workspaceId, {
            embedDetails: { accessLevel: 'View' },
            reportId: report.id,
            workspaceId: workspaceId,
            type: 'reports'
          });

          const embedToken = await embedTokenResponse;


          const embedConfig = {
            type: 'report',
            tokenType: pbi.models.TokenType.Embed,
            accessToken: embedToken,
            embedUrl: report.embedUrl,
            pageView: "fitToWidth",
            id: report.id,
            pageName: urlPageName,
            permissions: pbi.models.Permissions.All,
            viewMode: pbi.models.ViewMode.View,

            settings: {
              DisplayOption: pbi.models.DisplayOption.FitToWidth,
              ...(isMobileDevice() && {
                layoutType: pbi.models.LayoutType.MobilePortrait
              }),
              filterPaneEnabled: true,
              navContentPaneEnabled: false,
            }
          };

          const newReport = powerbi.embed(reportContainer, embedConfig);
          setReportInstance(newReport);

          newReport.on('loaded', () => {
            console.log('Report loaded successfully');
            setIsLoaded(true);
            newReport.getPages().then((pages) => {
              console.log('Report pages:', pages);
            });
          });

          newReport.on('error', (event) => {
            console.error('Error loading report:', event.detail);
            console.log(`Error loading report: ${event.detail.message}`);
          });
        }
      } catch (error) {
        console.error("Error embedding Power BI report:", error);
        console.log(error.message);
      }
    }

    bootstrapReport();
  }, [reportId, workspaceId, powerbi]);

  useEffect(() => {
    if (!reportInstance || !urlPageName) return;

    async function navigateToPage() {
      try {
        console.log('Navigating to page:', urlPageName);
        await reportInstance.setPage(urlPageName);
        console.log('Successfully navigated to page:', urlPageName);
      } catch (error) {
        console.error('Error navigating to page:', error);
        console.log(`Failed to navigate to page: ${urlPageName}`);
      }
    }

    navigateToPage();
  }, [urlPageName, reportInstance]);

  useEffect(() => {
    return () => {
      if (reportInstance) {
        reportInstance.off('loaded');
        reportInstance.off('error');
      }
    };
  }, [reportInstance]);

  useEffect(() => {
    if (isLoaded) {
      stopLoading('report-load');
    }
  }, [isLoaded]);

  if (error) {
    return (
      <div className="content">
        <h3>Error loading report</h3>
        <p>{error}</p>
      </div>
    );
  }

  const reportContainerStyle = {
    position: 'relative',
    width: '100%',

    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    visibility: isLoaded ? 'visible' : 'hidden'
  };

  return (
    <div 
      id="reportContainer" 
      className="report-container report-content"
      style={reportContainerStyle}
    />
  );
}

export default Report;