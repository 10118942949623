import React from 'react';
import { Keyboard } from 'lucide-react';
import './settings.css';

const KeyboardSettings = () => {
  const shortcuts = [
    { key: '⌘ + K', description: 'Open command palette' },
    { key: '⌘ + /', description: 'Show keyboard shortcuts' },
    { key: '⌘ + N', description: 'Create new item' },
    { key: '⌘ + S', description: 'Save changes' },
  ];

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <div className="p-3 rounded-full bg-pink-500/10">
          <Keyboard className="w-6 h-6 text-pink-500" />
        </div>
        <div>
          <h1 className="text-2xl font-semibold text-white">Keyboard Shortcuts</h1>
          <p className="text-gray-400">View and customize keyboard shortcuts</p>
        </div>
      </div>

      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/10 p-6">
        <div className="space-y-4">
          {shortcuts.map((shortcut, index) => (
            <div key={index} className="flex items-center justify-between p-4 bg-gray-700/50 rounded-lg">
              <span className="text-gray-400">{shortcut.description}</span>
              <kbd className="px-3 py-1 bg-gray-900 text-white rounded-lg font-mono text-sm">
                {shortcut.key}
              </kbd>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KeyboardSettings; 