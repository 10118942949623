import React, { useState } from 'react';
import { Smartphone, Trash2 } from 'lucide-react';
import './settings.css';

const DeviceSettings = () => {
  const [devices] = useState([
    { id: 1, name: 'MacBook Pro', lastActive: 'Now', current: true },
    { id: 2, name: 'iPhone 13', lastActive: '2 hours ago', current: false },
  ]);

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <div className="p-3 rounded-full bg-cyan-500/10">
          <Smartphone className="w-6 h-6 text-cyan-500" />
        </div>
        <div>
          <h1 className="text-2xl font-semibold text-white">Device Settings</h1>
          <p className="text-gray-400">Manage your connected devices</p>
        </div>
      </div>

      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/10 p-6">
        <div className="space-y-4">
          {devices.map(device => (
            <div key={device.id} className="flex items-center justify-between p-4 bg-gray-700/50 rounded-lg">
              <div>
                <h3 className="text-white font-medium flex items-center gap-2">
                  {device.name}
                  {device.current && (
                    <span className="text-xs px-2 py-1 bg-green-500/20 text-green-400 rounded-full">
                      Current Device
                    </span>
                  )}
                </h3>
                <p className="text-gray-400 text-sm">Last active: {device.lastActive}</p>
              </div>
              {!device.current && (
                <button className="p-2 hover:bg-red-500/20 rounded-lg text-red-400 transition-colors">
                  <Trash2 className="w-5 h-5" />
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeviceSettings; 