import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Home, BarChart2, Users, Settings, X, ChevronDown, ChevronRight, FileText } from 'lucide-react';
import { getReports, getOrgConfig, getReportPages } from '../../utils/api';
import hotelIcon from '../../assets/img/hotel--icon.svg';

const NavItem = ({ icon: Icon, label, isCollapsed, active, hasChildren, isOpen, onToggle, children, to }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (hasChildren) {
      onToggle();
    } else if (to) {
      navigate(to);
    }
  };

  return (
    <div className="relative">
      <div 
        className={`
          flex items-center p-3 rounded-lg cursor-pointer 
          ${active 
            ? 'bg-orange-50 dark:bg-white/10 text-orange-500' 
            : 'hover:bg-gray-100 dark:hover:bg-white/5'
          }
          ${isCollapsed ? 'justify-center' : ''}
        `}
        onClick={handleClick}
      >
        <div className={isCollapsed ? 'mx-auto' : ''}>
          <Icon size={20} className={`${active ? 'text-orange-500' : 'text-gray-600 dark:text-gray-300'}`} />
        </div>
        {!isCollapsed && (
          <>
            <span className={`ml-3 ${active ? 'text-orange-500' : 'text-gray-800 dark:text-gray-200'}`}>{label}</span>
            {hasChildren && (
              <div className="ml-auto">
                {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
              </div>
            )}
          </>
        )}
      </div>
      {hasChildren && !isCollapsed && isOpen && (
        <div className="ml-8 mt-1 space-y-1">
          {children}
        </div>
      )}
    </div>
  );
};

const SubNavItem = ({ label, active, to }) => {
  const navigate = useNavigate();
  const isCollapsed = false;

  return (
    <div 
      className={`
        p-2 rounded-lg cursor-pointer text-sm 
        ${active 
          ? 'bg-orange-50 dark:bg-white/10 text-orange-500' 
          : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-white/5'
        }
        ${isCollapsed ? 'text-center' : ''}
      `}
      onClick={() => to && navigate(to)}
    >
      {label}
    </div>
  );
};

const Sidebar = ({ isCollapsed, onToggle }) => {
  const [openItems, setOpenItems] = useState({});
  const [reports, setReports] = useState([]);
  const [workspaceId, setWorkspaceId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get workspace ID
        const orgConfig = await getOrgConfig();
        const wsId = orgConfig[0].workspaceId;
        setWorkspaceId(wsId);

        // Get reports
        const reportsResponse = await getReports(wsId);
        
        // Get pages for each report
        const reportsWithPages = await Promise.all(
          reportsResponse.value.map(async (report) => {
            try {
              const pagesResponse = await getReportPages(report.id, wsId);
              return {
                ...report,
                pages: pagesResponse.value
              };
            } catch (error) {
              console.error(`Error fetching pages for report ${report.id}:`, error);
              return {
                ...report,
                pages: []
              };
            }
          })
        );

        setReports(reportsWithPages);
        
        // Initialize openItems state for reports
        const initialOpenItems = reportsWithPages.reduce((acc, report) => ({
          ...acc,
          [`report_${report.id}`]: false
        }), {
          operations: false,
          staff: false,
          reports: false
        });
        
        setOpenItems(initialOpenItems);

      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };

    fetchData();
  }, []);

  const handleReportClick = (report) => {
    // Navigate to first page
    if (report.pages && report.pages.length > 0) {
      navigate(`/report/${report.id}/page/${report.pages[0].name}`);
    }
    
    // Keep reports section open and toggle this report's pages to be visible
    setOpenItems(prev => ({
      ...prev,
      reports: true, // Keep main reports section open
      [`report_${report.id}`]: true // Always expand this report's pages
    }));
  };

  const handleMainReportsClick = () => {
    // Only toggle the main reports section
    setOpenItems(prev => ({
      ...prev,
      reportsCollapse: !prev.reportsCollapse
    }));
  };

  const toggleItem = (item) => {
    setOpenItems(prev => ({ ...prev, [item]: !prev[item] }));
  };

  // Helper function to check if a report is active
  const isReportActive = (reportId) => {
    return location.pathname.includes(`/report/${reportId}`);
  };

  // Helper function to check if a page is active
  const isPageActive = (reportId, pageName) => {
    return location.pathname === `/report/${reportId}/page/${pageName}`;
  };

  return (
    <div 
      className={`fixed left-0 top-0 h-full bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm border-r border-gray-200/50 dark:border-gray-700/50 transition-all duration-300 ${isCollapsed ? 'w-20 hover:w-64' : 'w-64'}`}
      onMouseEnter={() => isCollapsed && onToggle()}
      onMouseLeave={() => !isCollapsed && onToggle()}
    >
      <div className="h-16 flex items-center justify-between px-4 border-b border-gray-200">
        <div className={`flex items-center ${isCollapsed ? 'w-full justify-center' : ''}`}>
          <button onClick={onToggle} className="flex items-center">
            <img 
              src={hotelIcon}
              alt="Hotel Icon"
              className="w-6 h-6"
            />
            {!isCollapsed && <span className="text-xl font-semibold text-gray-800 dark:text-gray-200 ml-2">lodgeic</span>}
          </button>
        </div>
        {!isCollapsed && (
          <button onClick={onToggle} className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700/50 rounded-lg">
            <X size={20} className="text-gray-600 dark:text-gray-300" />
          </button>
        )}
      </div>
      
      <nav className="p-4 space-y-2">
        <NavItem 
          icon={Home} 
          label="Dashboard" 
          isCollapsed={isCollapsed} 
          active={location.pathname === '/dashboard'}
          to="/dashboard"
        />
        
        <NavItem 
          icon={FileText}
          label="Analytics" 
          isCollapsed={isCollapsed}
          active={location.pathname.includes('/report')}
          hasChildren={true}
          isOpen={openItems.reports}
          onToggle={() => toggleItem('reports')}
        >
          {reports.map((report) => (
            <NavItem
              key={report.id}
              icon={FileText}
              label={report.name}
              isCollapsed={isCollapsed}
              active={isReportActive(report.id)}
              hasChildren={report.pages.length > 0}
              isOpen={openItems[`report_${report.id}`]}
              onToggle={() => handleReportClick(report)}
            >
              {report.pages.map((page) => (
                <SubNavItem
                  key={page.name}
                  label={page.displayName}
                  active={isPageActive(report.id, page.name)}
                  to={`/report/${report.id}/page/${page.name}`}
                />
              ))}
            </NavItem>
          ))}
        </NavItem>

        {/* <NavItem 
          icon={BarChart2} 
          label="Operations" 
          isCollapsed={isCollapsed} 
          active={location.pathname === '/operations'}
          to="/operations"
        /> */}
        {/* <NavItem 
          icon={Users} 
          label="Staff" 
          isCollapsed={isCollapsed} 
          active={location.pathname.includes('/staff')}
          hasChildren={true}
          isOpen={openItems.staff}
          onToggle={() => toggleItem('staff')}
        >
          <SubNavItem label="Schedule" active={location.pathname.includes('/staff/schedule')} to="/staff/schedule" />
          <SubNavItem label="Performance" active={location.pathname.includes('/staff/performance')} to="/staff/performance" />
        </NavItem> */}
        <NavItem 
          icon={Settings} 
          label="Settings" 
          isCollapsed={isCollapsed} 
          active={location.pathname === '/settings'}
          to="/settings"
        />
      </nav>
    </div>
  );
};

export default Sidebar; 