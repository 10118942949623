import React from 'react';
import { useLoading } from '../context/LoadingContext';

const TopLoadingBar = () => {
  const { isLoading } = useLoading();
  
  return (
    <div 
      className={`
        fixed top-0 left-0 h-0.5 bg-orange-500 transition-all duration-300 z-50
        ${isLoading ? 'w-full' : 'w-0'}
      `}
      style={{
        animation: isLoading ? 'loading 2s ease-in-out infinite' : 'none'
      }}
    >
      <style>
        {`
          @keyframes loading {
            0% {
              transform: translateX(-100%);
            }
            50% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(100%);
            }
          }
        `}
      </style>
    </div>
  );
};

export default TopLoadingBar; 