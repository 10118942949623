import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';

function ProtectedRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(null);

  React.useEffect(() => {
    checkAuth();
  }, []);

  async function checkAuth() {
    try {
      await fetchAuthSession();
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Auth check failed:', error);
      setIsAuthenticated(false);
    }
  }

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children || <Outlet />;
}

export default ProtectedRoute; 