import React, { createContext, useContext, useState } from 'react';

const LoadingContext = createContext();

export function LoadingProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingQueue, setLoadingQueue] = useState(new Set());

  const startLoading = (id) => {
    setLoadingQueue(prev => new Set(prev).add(id));
    setIsLoading(true);
  };

  const stopLoading = (id) => {
    setLoadingQueue(prev => {
      const newQueue = new Set(prev);
      newQueue.delete(id);
      if (newQueue.size === 0) {
        setIsLoading(false);
      }
      return newQueue;
    });
  };

  return (
    <LoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>
      {children}
    </LoadingContext.Provider>
  );
}

export const useLoading = () => useContext(LoadingContext); 