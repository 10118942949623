import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsconfig from './utils/awsConfig';
import { Toaster } from 'react-hot-toast';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/Layout/MainLayout';
import Dashboard from './components/Dashboard';
import Report from './components/Report';
import { LoadingProvider } from './context/LoadingContext';
import './assets/css/chat.css';
import Settings from './components/Settings';
import SettingsLayout from './components/Settings/SettingsLayout';
import EmailSettings from './components/Settings/EmailSettings';
import NotificationSettings from './components/Settings/NotificationSettings';
import PrivacySettings from './components/Settings/PrivacySettings';
import TeamSettings from './components/Settings/TeamSettings';
import BillingSettings from './components/Settings/BillingSettings';
import WorkspaceSettings from './components/Settings/WorkspaceSettings';
import DeviceSettings from './components/Settings/DeviceSettings';
import KeyboardSettings from './components/Settings/KeyboardSettings';
import AppearanceSettings from './components/Settings/AppearanceSettings';

const AUTH = Amplify.configure(awsconfig);

function App() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <LoadingProvider>
      <Routes>
        {/* Public route */}
        <Route path="/login" element={<Login />} />

        {/* Protected routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="/dashboard" replace />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="dashboard/:dashboardId" element={<Dashboard />} />
          <Route path="report/:reportId" element={<Report />} />
          <Route path="report/:reportId/page/:pageName" element={<Report />} />
          <Route path="settings" element={<SettingsLayout />}>
            <Route index element={<Navigate to="/settings/email" replace />} />
            <Route path="email" element={<EmailSettings />} />
            <Route path="notifications" element={<NotificationSettings />} />
            <Route path="privacy" element={<PrivacySettings />} />
            <Route path="team" element={<TeamSettings />} />
            <Route path="billing" element={<BillingSettings />} />
            <Route path="workspace" element={<WorkspaceSettings />} />
            <Route path="devices" element={<DeviceSettings />} />
            <Route path="shortcuts" element={<KeyboardSettings />} />
            <Route path="appearance" element={<AppearanceSettings />} />
          </Route>
        </Route>

        {/* Catch all */}
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>

      <Toaster position="top-right" />
    </LoadingProvider>
  );
}

export default App; 